export const environment = {
    production: false,
    apiBaseUrl: 'https://amap-api.amalitech-dev.net',
    AWS_ACCESS_KEY_ID:'AKIA3VG3WJFVT4ANMRFF',
    AWS_SECRET_ACCESS_KEY:'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',
    AWS_REGION: "eu-west-1",
    AWS_BUCKET_NAME: "imocha-media-files",
    eventUrl:'https://amap-notification-service.amalitech-dev.net/api/v1/notification/stream',
    apiReportTest:'https://amap-report-test.amalitech-dev.net',
    NODE_ENV: '',
    localFrontendLocalBaseUrl: 'http://localhost:4200',
    analytics: {
        segmentWriteKey: 'c1LWaBUioPTFQodkn6zR1Afjmo65qONd'
    }
}